import React from 'react';
import './Footer.scss';
import SocialList from '../SocialList/SocialList';
import Contacts from '../Contacts/Contacts';
import FooterLogo from "../Logo/FooterLogo";

const Footer = () => {
  return (
    <footer name="contact us" className="footer">
      <div className="container footer-wrapper">
        <div className="footer-wrapper__item align-center">
          <FooterLogo/>
        </div>
        <div className="footer-wrapper__item">
          <div className="contacts-mail">
            <a href="mailto:+380978036873">info@argentum.travel</a>
          </div>
          <Contacts/>
          <SocialList/>
        </div>
        {/*<div className="footer-wrapper__item">*/}
        {/*  <Contacts/>*/}
        {/*</div>*/}
        <div className="footer-wrapper__item">
          <address className="contacts-address">
            3B Henuez`ka str. <br/>
            Odessa, Ukraine, 65062
          </address>
          <address className="contacts-address">
            100 Parnavaz Mepe str.,<br/>
            Batumi, Georgia
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;