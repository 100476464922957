import React, { useState, useEffect } from 'react';
import './Header.scss';
import Navigation from '../Navigation/Navigation';
import cn from 'classnames';
import Logo from '../Logo/Logo';
import PropTypes from 'prop-types';
import BurgerButton from '../BurgerButton/BurgerButton';

const Header = ({data, isHeaderDark, scrollHeight, handelModalClick}) => {
    const [isHeaderOpened, setIsHeaderOpened] = useState(false);
    
    useEffect(() => {
        setIsHeaderOpened(false);
    }, [scrollHeight])

    const handleBurgerClick = () => {
        setIsHeaderOpened(() => !isHeaderOpened)
    }

    const handleNavClick = () => {
        setIsHeaderOpened(() => false);
    }

    return (
        <header className={ cn("header", {"header_black": isHeaderDark})}>
            <div className="container header-wrapper">
                <Logo />
                <Navigation data={data} isInHeader={true} onClick={handleNavClick} isHeaderOpened={isHeaderOpened} handelModalClick={handelModalClick} />
                <BurgerButton isActive={isHeaderOpened} onClick={handleBurgerClick} />
            </div>
        </header>
    );
};


Header.propTypes = {
    data: PropTypes.array.isRequired,
    isHeaderDark: PropTypes.bool,
    scrollHeight: PropTypes.number,
    handelModalClick: PropTypes.func
}

export default Header;