import React from 'react';
import './Logo.scss';
import logoPng from '../../assets/images/logo.png'
import { Link } from 'react-scroll';
const Logo = () => {
    return (
        <div className="logo">
            <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
            ><img width="200" src={logoPng} alt=""/></Link>   
        </div>
    );
};

export default Logo;