import {request} from "./request.service";
import { API_BASE_V1, METHOD } from './constants'

export const postQuote = async (data) => {
    return await request('quote/', {
        api: API_BASE_V1,
        method: METHOD.POST,
        withoutToken: true,
        body: JSON.stringify(data),
    })
}
export const postFeedback = async (data) => {
    return await request('feedback/', {
        api: API_BASE_V1,
        method: METHOD.POST,
        withoutToken: true,
        body: JSON.stringify(data),
    })
}