import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames'
import './BurgerButton.scss';

const BurgerButton = ({onClick, isActive}) => {
    return (
        <div onClick={onClick} className={cn("burger-icon", {"open": isActive})}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};

BurgerButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired
}

export default BurgerButton;