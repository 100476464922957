import React from 'react';
import './SelectGroup.scss';
import sailorsPNG from '../../assets/images/offer-photos/sailors.png';
import visaPNG from '../../assets/images/offer-photos/visa.png';
import insurancePNG from '../../assets/images/offer-photos/incurance.png';
import airPNG from '../../assets/images/offer-photos/air-plane.png';
import corporatePNG from '../../assets/images/offer-photos/corporate.png';
import corporateTravelPNG from '../../assets/images/offer-photos/corporate-travel.png';



class SelectGroup extends React.Component {

    state = {
        data: [
            {
                name: 'Marine Travel',
                description: "With years of experience in the maritime sector, Argentum Travel is a travel partner chosen by many of the world's most successful shipping organizations. Our worldwide coverage, round-the-clock expertise and technologies designed specifically for the maritime industry, allow your organization to solve the challenges of modern shipping with the cost, efficiency and safety at the forefront of any strategy.",
                image: sailorsPNG,
                size: "lg",
                link: '/test'
            },
            { 
                name: "Travel Support",
                description: "Our 24/7 service team are also on hand to manage e-tickets, re-ticketing and flight re-validations, new urgent bookings, amendments or changes to existing bookings, assistance with delayed, cancelled flights, missed connections and lost baggage.",
                image: visaPNG,
                size: "sm",
                link: '/test'
            },
            {
                name: "Visa Support",
                description: "We will make sure you hold the appropriate documentation and save you time with proper guidance or by taking over the overall procedure.",
                image: insurancePNG,
                size: "lg",
                link: '/test'
            },
            {
                name: "Yacht Travel",
                description: "Argentum Travel is the travel management partner of choice for yacht management servicing companies and super yacht crews around the world. Our global reach, 24/7 service and innovative technology enables your organisation to navigate the sector’s high demand for speed and efficiency of service, as well as cost-effective solutions",
                image: airPNG,
                size: "sm",
                link: '/test'
            },
            {
                name: "Insurance",
                description: "We know that every individual has different needs and security woes, from avoiding high international medical costs to ensuring less stress during any loss of luggage. Keeping this very thought in mind, we have tied-up with reputed insurance providers, allowing us to offer multiple travel insurance policies at discounted rates.",
                image: corporatePNG,
                size: "sm",
                link: '/test'
            },
            {
                name: "Corporate Travel",
                description: "With years of experience in the maritime sector, Argentum Travel is a travel partner chosen by many of the world's most successful shipping organizations. Our worldwide coverage, round-the-clock expertise and technologies designed specifically for the maritime industry, allow your organization to solve the challenges of modern shipping with the cost, efficiency and safety at the forefront of any strategy.",
                image: corporateTravelPNG,
                size: "lg",
                link: '/test'
            }
        ]
    }
    
    handleClick = (item) => {
        this.props.setModalDisplay(true)
        this.props.onClick(item)
    }

    render() {

        return (
            <div className="select-group">

                {this.state.data.map(({name, description, image, size}, index) => {
                    return (
                        <div onClick={() => this.handleClick(name)} key={index} className={`select-group__item select-group__item_${index + 1} ${size}`}>
                            <div className="group-item-container" style={{backgroundImage: `url(${image})`}}>
                            </div>
                            <div className="select-group__item__content">
                                <div className="select-group__item__text">
                                    {description}
                                </div>
                            </div>
                            <div className="select-group__item__title">
                                {name}
                            </div>
                        </div>
                    )
                })}               
                
            </div>
        );
    }
    
};

export default SelectGroup;