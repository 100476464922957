import React from 'react';
import './Alert.scss';
const Alert = React.memo(({content}) => {    
    return (
        <div className="alert">
            <div className="alert__title">
                {content.title}
            </div>
            <div className="alert__content">
                {content.text}
            </div>
        </div>
    );
});

export default Alert;