import React from 'react';
import './DayPickerWidget.scss';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const DayPicker = ({minDate, title, value, onChange}) => {

  const handleDayChange = (day) => {
    onChange(day, title);    
  }
  
  if(minDate > value) value = minDate;
 
    return (
      <div className="day-picker-item">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar={false}
            variant="inline"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            minDate={minDate}
            label={title}
            value={value}
            onChange={handleDayChange}
            KeyboardButtonProps = {{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
    );
  
}

export default DayPicker;