import React from 'react';
import './SocialList.scss';
import instIconSvg from '../../assets/images/icons/inst.svg';
import facebookIconSvg from '../../assets/images/icons/facebook.svg';

const SocialList = () => {
    return (
        <ul className="social-list">
            <li className="social-list__item"><a href="https://instagram.com/argentum.travel?utm_medium=copy_link" rel="noopener noreferrer" target='_blank'><img src={instIconSvg} alt="instagram Icon"/></a></li>
            <li className="social-list__item"><a href="https://www.facebook.com/argentum.travel" rel="noopener noreferrer" target='_blank'><img src={facebookIconSvg} alt="facebook Icon"/></a></li>
        </ul>
    );
};

export default SocialList;