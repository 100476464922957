import React from 'react';
import './Contacts.scss';
import '../../variables.scss'
const Contacts = () => {
    return (
        <div className="contacts-list">

            <div className="contacts-list__item">
                <a href="tel:+380978036873">+38 097 803 68 73</a>
            </div>
            <div className="contacts-list__item">
                <a href="tel:+380487361717">+38 048 736 17 17</a>
            </div>
        </div>
    );
};

export default Contacts;