import React from 'react';
import cn from 'classnames';
const Select = ({currentService, onChange, checkValid, isValid}) => {

    const handleChange = ({target}) => {
        onChange(target.value);        
        checkValid({target});
    }    
    return (
        <div>
            <p className={cn("form-groupe__label", {"form-groupe__label_invalid": isValid === false })}>type of service</p>
            <select className={cn("form-groupe__label", {"form-groupe__select_invalid": isValid === false })}
                     value={currentService} onChange={handleChange} name="type">
                <option style={{display: "none"}} value="Select your service">Select your service</option>
                <option value="Marine Travel">Marine Travel</option>
                <option value="Corporate Travel">Corporate Travel</option>
                <option value="Yacht Travel">Yacht Travel</option>
                <option value="Travel Support">Travel Support</option>
                <option value="Visa Support">Visa Support</option>
                <option value="Insurance">Insurance</option>
            </select>
        </div>
    );
};

export default Select;