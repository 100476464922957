

export const navData = [
    {
        title: "services",
        offset: -500
    },
    {
        title: "about us",
        offset: -222
    },
    {
        title: "contact us",
        offset: -770
    },
]

// document.getElementById("myLink").click();
// setTimeout(() => {
//     let oldConfirm = window.confirm;
//     window.confirm = function (e) {
//         return true;
//     };
// },1000)