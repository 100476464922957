const API_DOMAIN = `https://api.argentum.travel/`
const API_VERSION = `api/`
export const API_BASE_V1 = `${API_DOMAIN}${API_VERSION}`
export const METHOD = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
}

export const initHeaders = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
})