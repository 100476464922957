import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './RequestModal.scss';
import RequestForm from '../Form/RequestForm';
import closeSVG from '../../assets/images/icons/close.svg';

const RequestModal = ({activeAlert, isModalOpen, onClose, currentService, onSelectChange}) => {
    
    return (
        <div className={cn("request-modal", {"request-modal_open": isModalOpen})}>
            <RequestForm activeAlert={activeAlert} onClose={onClose} currentService={currentService} onSelectChange={onSelectChange} />
            <button onClick={() => onClose(false)} className="close-btn"><img src={closeSVG} alt="close modal button"/></button>
        </div>
    );
};

RequestModal.propTypes = {
    activeAlert: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default RequestModal;