import {API_BASE_V1, initHeaders, METHOD} from "./constants";

export const request = async (endpoint, options) => {
    const headers = options?.headers ?? initHeaders

    const init = {
        body: options?.body,
        method: options?.method || METHOD.GET,
        headers,
    }

    const domain = options?.api ? options.api : API_BASE_V1

    try {
        const response = await fetch(`${domain}${endpoint}`, init)

        if (response.status === 401) {
            window.location.href = '/'
            return
        }

        const data = await response.json()

        if (!response.ok) {
            return Promise.reject(data)
        }

        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}
