import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './Form.scss';
import Field from '../Field/Field';
import { checkFieldValid } from '../../services/validationService';
import DaypickerWidget from '../DayPickerWidget/DayPickerWidget';
import Select from '../Select/Select';
import {postQuote} from "../../api/postApi";

class RequestForm extends PureComponent {
    
    state = {
        isFormDidSend: false,
        fields: {
            type : {
                name: 'type of service',
                value: 'Select your service',
                validType: 'noDefaulte',
                isValid: null
            },
            phone: {
                name: 'phone',
                validType: 'phone',
                value: '',
                isValid: null,
                placeholder:'phone'
            },
            email: {
                name: 'email',
                validType: 'email',
                value: '',
                isValid: null,
                placeholder:'email'
            },
            from: {
                name: 'from',
                value: new Date(),
                isValid: true
            },

            to: {
                name: 'to',
                value: new Date(),
                isValid: true
            },

            message: {
                name: 'message',
                validType: 'noReq',
                value: '',
                isValid: null
            },

        }
    }
    
    handleChange = ({target}) => {
        this.setState((state) =>  {            
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [target.name]: {
                        ...state.fields[target.name],
                        value: target.value,
                        isValid: checkFieldValid(state.fields[target.name].validType, target.value)
                    }
                }
            }
        })
    }

    handleDaypickerChange = (day, name) => {
        this.setState((state) =>  {            
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [name]: {
                        ...state.fields.name,
                        name,
                        value: day,
                    }
                }
            }
        })
    }

    submitHandler = async (event) => {
        event.preventDefault();
        const { fields } = this.state;

        const isValidFields = Object.keys(fields).every(key => {
            const isValid = checkFieldValid(fields[key].validType, fields[key].value);
            this.setState(prevState => ({
                fields: {
                    ...prevState.fields,
                    [key]: {
                        ...prevState.fields[key],
                        isValid
                    }
                }
            }));
            return isValid;
        });

        if (isValidFields) {
            const data = {
                type: fields.type.value,
                phone: fields.phone.value,
                email: fields.email.value,
                date_from: fields.from.value.toISOString(),
                date_to: fields.to.value.toISOString(),
                message: fields.message.value
            };

            await postQuote(data);

            this.setState({ isFormDidSend: true });
            this.props.activeAlert({ title: 'Thank you', text: 'Your submission has been received.' });
            this.props.onClose(false);
        }
    }
    render() {        

        const {fields} = this.state;
        return (
            <form className="form" autoComplete="false" onSubmit={this.submitHandler}>
                
                <div className="select-wrapper">
                    <Select name={fields.type.name} 
                            isValid={fields.type.isValid} 
                            currentService={this.props.currentService} 
                            checkValid={this.handleChange} 
                            onChange={this.props.onSelectChange} 
                    />
                </div>

                <div className="date-picker-wrapper">
                    
                    <DaypickerWidget
                        name={fields.from.name} 
                        minDate={new Date()}
                        value={fields.from.value}
                        onChange={this.handleDaypickerChange} 
                        title={fields.from.name} />
                
                    <DaypickerWidget
                        name={fields.to.name} 
                        value={fields.to.value}
                        minDate={fields.from.value}  
                        onChange={this.handleDaypickerChange} 
                        title={fields.to.name} />
                    
                </div>

                <Field 
                    name={fields.phone.name}
                    isValid={fields.phone.isValid}
                    placeholder={fields.phone.placeholder}
                    value={fields.phone.value}
                    onChange={this.handleChange}
                />
                <Field
                    name={fields.email.name}
                    isValid={fields.email.isValid}
                    placeholder={fields.email.placeholder}
                    value={fields.email.value}
                    onChange={this.handleChange}
                />
                <Field 
                    name={fields.message.name}
                    isTextarea
                    isValid={fields.message.isValid}
                    placeholder={fields.message.placeholder}
                    value={fields.message.value}
                    onChange={this.handleChange}
                />
                
                <button disabled={this.state.isFormDidSend} className="button form__submit form__submit_dark" type="submite">
                    Send
                </button>
            </form>
        );
    }
};

RequestForm.propTypes = {
    activeAlert: PropTypes.func.isRequired,
}

export default RequestForm;