import React from 'react';
import ReactInview from 'react-inview-js';
import cn from 'classnames';

import './CircularChart.scss';

class CircularChart extends React.Component {
    
    render() {
        const {value, text, elementIsInView} = this.props;
        
        return (
            <div className="circular-chart-wrapper">
                <svg viewBox="0 0 36 36" className="circular-chart">
                    <path className={cn("circle", {"active": elementIsInView})}
                        strokeDasharray="0, 100"
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                </svg>
                <div className="chart">
                    <h2 className="chart__value">
                        {value}
                    </h2>
                    <div className="chart__desc">
                        {text}
                    </div>
                </div>
            </div>
            
        );
    }
};
const InviewOptions = {offsetY: 0, showGuides: false, fullElementInView: true}
export default ReactInview(InviewOptions)(CircularChart);