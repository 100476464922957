import React from 'react';
import './Logo.scss';
import logoSvg from '../../assets/images/logo.svg'
import { Link } from 'react-scroll';
const Logo = () => {
  return (
    <div className="logo">
      <Link
        activeClass="active"
        to="home"
        spy={true}
        smooth={true}
        offset={0}
        duration={500}
      ><img width="200" src={logoSvg} alt=""/></Link>
    </div>
  );
};

export default Logo;