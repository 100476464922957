import React from 'react';

import planeVideo from '../../assets/videos/air-plane.mp4';
import planePosterImg from '../../assets/images/plane-poster.png';
import './BgVideo.scss';
const BgVideo = React.memo(() => {
    return (
        <div className="bg-video" >
            <video 
                className="bg-video__item" 
                playsInline 
                poster={planePosterImg}
                autoPlay="autoplay" 
                muted loop id="myVideo"
            >
                <source src={planeVideo} type="video/mp4" />
                <img src={planePosterImg} alt="videoPosterImg"/>
            </video>        
        </div>
    );
});

export default BgVideo;