import React, { useState, useEffect, useRef } from 'react';
import './Accordeon.scss';
import Form from '../Form/Form';
const Accordeon = ({activeAlert}) => {

    const [activeBlock, setActiveBlock] = useState(null);

    const handleClick = (activeClass) => {
        setActiveBlock(activeClass);
    }

    const accordEl = useRef(null);
    
    useEffect(() => {
        if(accordEl.current) {
            accordEl.current.focus()
            document.addEventListener('click', handleClickOutside, false);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, false);
        }
    })

    const handleClickOutside = (event) => {
        if (!accordEl.current.contains(event.target)) {
            setActiveBlock(null);
        }
    }
    
    return (
        <div className={`accord ${activeBlock}`} ref={accordEl}>
            <div onClick={() => handleClick('active-first')} className="accord__item accord__item_first">
                <div className="noskew">
                    <div className="background">
                        <div className="accord-content">
                            <div className="title">
                                About Us
                            </div>
                            <div className="accord__item-desc">
                                We are a full service travel agency! Our team is distinguished by one striking feature
                                – we take on the solution of any tasks in the field of travel. In 2013, we began as a
                                travel agency serving the maritime company, providing comprehensive support to seafarers
                                Our goal: to offer the best travel services in the market, inspire customers to discover
                                new horizons and expand their own horizons thanks to successful cooperation with partners 
                                around thе world. Join the community of Argentum Travel – together we create a new look 
                                at the world of travel.
                            </div>
                            
                        </div>
                        <div className="click-trigger">
                            Click to view details 
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={() => handleClick('active-second')} className="accord__item accord__item_second">
                <div className="noskew">
                    <div className="background">
                        <div className="accord-content">
                            <div className="title">
                                Contact Us
                            </div>
                        </div>
                        <Form activeAlert={activeAlert} />
                        <div className="background_text">
                            <span style={{paddingRight: 30}}>ARGENTUM</span><span>TRAVEL</span>
                            <div className="click-trigger">
                                Click to view details 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accordeon;