import React from 'react';
import PropTypes from 'prop-types'
import { Link } from "react-scroll";
import cn from 'classnames';

import './Navigation.scss';

const Navigation = ({data, type, isInHeader, isHeaderOpened, onClick, handelModalClick}) => {
    return (
        <nav className={cn("navigation", {"header-navigation": isInHeader, "opened": isHeaderOpened})}>
            <ul className={cn("nav", {"nav-column": type === "column"})}>
                {data.map(({title, offset}, index) => {
                    return (
                        <li key={title+index+type} className="nav__item">
                            <Link
                                onClick={onClick}
                                activeClass="active"
                                to={title}
                                spy={true}
                                smooth={true}
                                offset={offset}
                                duration={500}
                            >{title}</Link> 
                        </li>
                    )
                })}
                <li className="nav__item" onClick={() => handelModalClick(true)}> Request a Quote </li>
            </ul>
        </nav>
    );
};

Navigation.propTypes = {
    data: PropTypes.array.isRequired,
    type: PropTypes.string,
    isInHeader: PropTypes.bool,
    isHeaderOpened: PropTypes.bool,
    onClick: PropTypes.func
}

export default Navigation;

