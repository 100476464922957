import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './Form.scss';
import Field from '../Field/Field';
import { checkFieldValid } from '../../services/validationService';
import { postFeedback} from "../../api/postApi";
class Form extends PureComponent {

    state = {
        isFormDidSend: false,
        fields: {
            name: {
                name: 'name',
                validType: 'text',
                value: '',
                isValid: null
            },
            phone: {
                name: 'phone',
                validType: 'phone',
                value: '',
                isValid: null
            },
            email: {
                name: 'email',
                validType: 'email',
                value: '',
                isValid: null
            },
            message: {
                name: 'message',
                validType: 'text',
                value: '',
                isValid: null
            }
        }
    }
    
    handleChange = ({target}) => {
        this.setState((state) =>  {
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [target.name]: {
                        ...state.fields[target.name],
                        value: target.value,
                        isValid: checkFieldValid(target.name, target.value)
                    }
                }
            }
        })
    }

    submitHandler = async(event) => {
        event.preventDefault();
        const {fields} = this.state;
        const result = Object.keys(fields).map((key) => {
            this.setState((state) =>  {
                return {
                    ...state,
                    fields: {
                        ...state.fields,
                        [key]: {
                            ...fields[key],
                            isValid: checkFieldValid(fields[key].name, fields[key].value)
                        }
                    }
                }
            });
            return checkFieldValid(fields[key].name, fields[key].value);
        });

        if(!result.includes(false)) {
            const data = {
                email: fields.email.value,
                name: fields.name.value,
                phone: fields.phone.value,
                message: fields.message.value
            };

            await postFeedback(data);

            this.setState(({isFormDidSend}) => ({isFormDidSend: true}));
            this.props.activeAlert({title: 'Thank you', text: 'Your submission has been received.'});
        }
    }
    render() {        
        const {fields} = this.state;
        return (
            <form className="form" autoComplete="false" onSubmit={this.submitHandler}>

                {Object.keys(fields).map((key, index) => {
                    return (
                        <Field 
                            key={index}
                            name={fields[key].name}
                            isValid={fields[key].isValid}
                            value={fields[key].value}
                            onChange={this.handleChange}
                        />
                    )
                })}
                
                <button disabled={this.state.isFormDidSend} className="button form__submit" type="submite">Send</button>
            </form>
        );
    }
};

Form.propTypes = {
    activeAlert: PropTypes.func.isRequired,
}

export default Form;