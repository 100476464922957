import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
const Field = ({name, value, onChange, isValid, placeholder, isTextarea}) => {
    const TagName = isTextarea ? 'textarea' : 'input'
    if(!placeholder) placeholder = name;
    return (
        <div className={cn("form-groupe", {"form-groupe_invalid": isValid === false})}>
            <label className="form-groupe__label" htmlFor="email-field">{placeholder}</label>
            <TagName
                className="form-groupe__input" 
                type="text"
                value={value}
                name={name}
                onChange={onChange}
            />
        </div>
    );
};

Field.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isValid: PropTypes.bool,
}

export default Field;