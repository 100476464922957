
export const checkFieldValid = (type, value) => {
    switch(type) {
        case 'name':
            return (value || '').trim().length !== 0 && value.length > 1;
        case 'phone':
            return /^[+]*((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/.test(value);
        case 'message':
            return (value || '').trim().length !== 0 && value.length > 5;
        case 'email':
            return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
        case 'noEmpty':
            return (value || '').trim().length !== 0 && value.length > 1;
        case 'noReq':
            return true;
        case 'noDefaulte':
            return value !== "Select your service";
        case 'phone or email':
            return /^[+]*((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/.test(value) ||
                   /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
        default:
            return true;
    }
}


